import { useState } from "react";
import { Button } from "./Button";
import { createWalletInBot } from "../../hooks/functions";

export const CreateBot = ({ handleStatusMessage, refresh }) => {
  const [loading, setLoading] = useState(false);

  const handleCreateBotButton = async () => {
    setLoading(true);
    handleStatusMessage("");
    const res = await createWalletInBot();
    if (res.data.status === "success") {
      handleStatusMessage("A new bot was created successfully!");
      refresh()
    } else if (res.data.status === "failed") {
      handleStatusMessage(
        "The maximum number of bots is limited to 10. You reached to limits."
      );
    } else {
      handleStatusMessage("Failed! Server error! Try again later!");
    }
    setLoading(false);
  };

  return (
    <Button
      handleClick={handleCreateBotButton}
      isActive={loading}
      buttonText="Create Bot"
    />
  );
};
