import { useState } from "react";
import { MetricsModal } from "./MetricsModal";
import { Button } from "./Button";

export const Metrics = ({ tokenList }) => {
  const [openMetricsModal, setOpenMetricsModal] = useState(false);

  const handleMetricsButton = async () => {
    setOpenMetricsModal(true);
  };

  return (
    <>
      <Button
        handleClick={handleMetricsButton}
        isActive={false}
        buttonText="Metrics"
      />
      <MetricsModal
        tokenList={tokenList}
        openModal={openMetricsModal}
        setOpenModal={setOpenMetricsModal}
      />
    </>
  );
};
