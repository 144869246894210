import { useState, useEffect } from "react";
import { db, auth } from "../../utils/firebase";
import { getDocs, collection } from "firebase/firestore";
import { BotCard } from "./BotCard";
import { CreateBot } from "./CreateBot";
import { AddToken } from "./AddToken";
import { Setting } from "./Setting";
import { Metrics } from "./Metrics";
import { ClaimTokens } from "./ClaimTokens";
import { BotLoader } from "../../components/ContentLoader";
import { getApprovedTokens } from "../../hooks/handleApprovedTokens";

export const Bot = () => {
  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [botList, setBotList] = useState([]);
  const [approvedTokensList, setApprovedTokensList] = useState({});

  // Fetch Wallet Addresses
  const fetchWalletAddresses = async () => {

    const currentUser = auth.currentUser;
    const pathToCollection = `bots/${currentUser?.uid}/botDetails`;
    const collectionRef = collection(db, pathToCollection);
    const querySnapshot = await getDocs(collectionRef);
    const bots = [];
    querySnapshot.forEach((doc) => {
      bots.push(doc.id);
    });
    setBotList(bots);

  };

  useEffect(() => {
    let isMounted = true;

    // Fetch Token List
    const fetchTokens = async () => {
      const res = await getApprovedTokens();
      if (isMounted) setApprovedTokensList(res);
    };

    setLoading(true);
    fetchTokens();
    fetchWalletAddresses()
    setLoading(false);

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="flex flex-col w-full gap-4">
      {loading ? (
        <BotLoader />
      ) : (
        <>
          {/* Buttons */}
          <div className="w-full flex flex-col lp:flex-row gap-3 items-center">
            <CreateBot handleStatusMessage={setStatusMessage} refresh={fetchWalletAddresses} />
            <Setting wallets={botList} />
            <Metrics tokenList={approvedTokensList} />

            {auth.currentUser?.uid === "34e0ofLHx6Od3CXOD7Ayy26cUU83" && (
              <>
                <AddToken />
                <ClaimTokens />
              </>
            )}
          </div>

          {/* Status Message */}
          <div className="text-center text-[14px] text-green-500">
            {statusMessage}
          </div>

          {/* Bots */}
          <div className="w-full grid grid-cols-1 lp:grid-cols-2 tb:grid-cols-3 pc:grid-cols-4 gap-4 justify-items-center items-center">
            {botList.length > 0 &&
              botList.map((item, index) => (
                <BotCard
                  key={index}
                  walletAddress={item}
                  tokenList={approvedTokensList}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};
