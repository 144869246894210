import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import { Navigation } from "../../components/Navigation";
import { requestForToken } from "../../utils/firebase";

export const Shell = () => {
  useEffect(() => {
    function requestPermissions() {
      // Check if the browser supports notifications
      if ("Notification" in window) {
        // Request notification permission
        Notification.requestPermission().then(function (permission) {
          if (permission === "granted") {
            requestForToken();
          } else {
          }
        });
      } else {
        console.log("This browser does not support notifications.");
        alert("This browser does not support notifications.");
      }
    }

    requestPermissions();
    
  }, []);

  return (
    <div className="flex flex-col w-screen h-screen overflow-hidden">
      <Suspense fallback={<p>Loading...</p>}>
        <Navigation />
        <div className="overflow-x-hidden overflow-y-auto flex-1 min-h-screen bg-bgColor-light dark:bg-bgColor-dark">
          <div className="max-w-[1024px] mx-auto px-2 pt-10 pb-48 min-h-screen relative">
            <Outlet />
          </div>
        </div>
      </Suspense>
    </div>
  );
};
