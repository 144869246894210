import { getFunctions, httpsCallable } from "firebase/functions"
import axios from "axios"
import { getApprovedTokens } from "./handleApprovedTokens"

const callFirebaseFunction = async (functionName, params) => {
  const functions = getFunctions()
  const callableFunction = httpsCallable(functions, functionName)

  try {
    const result = await callableFunction(params)
    return result.data
  } catch (error) {
    console.error(`Error calling ${functionName}:`, error)
    throw error // Rethrow the error for higher-level handling
  }
}

export const buildNativeTx = async (toAddress, amount, index) => {
  return await callFirebaseFunction("buildTx", {
    toAddress,
    amount,
    index,
  })
}

export const swapTokens = async (quoteResponse, index, simulate) => {
  return await callFirebaseFunction("swapTokens", {
    quoteResponse,
    index,
    simulate,
  })
}

export const getUpdateUserBalances = async ({ force, accountUid = null }) => {
  const functions = getFunctions()
  const updateUserBalances = httpsCallable(functions, "getUpdateUserBalances")

  try {
    await updateUserBalances({ force, accountUid })
  } catch (error) {
    console.log(error)
  }
}

export const getTokenMetadata = async (chain, contractAddress) => {
  const functions = getFunctions()
  const getMetadata = httpsCallable(functions, "getTokenMetadata")

  let metadata = {}

  try {
    const result = await getMetadata({ chain, contractAddress })
    const data = result.data
    console.log(data)
    metadata = data.metadata
  } catch (error) {
    console.log(error)
  }
  return metadata
}

//updateWalletSignatures
export const updateWalletSignatures = async (accountUid, uid) => {
  const functions = getFunctions()
  const updateWalletSignatures = httpsCallable(
    functions,
    "updateWalletSignatures"
  )

  try {
    await updateWalletSignatures({ accountUid, uid })
  } catch (error) {
    console.log(error)
  } finally {
    return "done"
  }
}

export const updateName = async (firstName, lastName) => {
  const functions = getFunctions()
  const updateName = httpsCallable(functions, "updateName")
  try {
    return await updateName({ firstName, lastName })
  } catch (error) {
    console.log(error)
  }
}

export const createWalletInBot = async () => {
  const functions = getFunctions()
  const createWalletInBot = httpsCallable(functions, "createAddress")
  try {
    return await createWalletInBot({})
  } catch (error) {
    console.log(error)
  }
}

export const updateBotInfo = async (
  direction,
  baseToken,
  targetToken,
  walletAddress,
  mode,
  value,
  duration
) => {
  const functions = getFunctions()
  const updateBotInfo = httpsCallable(functions, "updateBotInfo")
  try {
    return await updateBotInfo({
      direction,
      baseToken,
      targetToken,
      walletAddress,
      mode,
      value,
      duration
    })
  } catch (error) {
    console.log(error)
  }
}
//

export const createTokenAddress = async (params) => {
  const functions = getFunctions()
  const createWallet = httpsCallable(functions, "createTokenAddress")

  let tokenSymbol = params.tokenSymbol
  let accountUid = params.accountUid

  console.log("createTokenAddress", tokenSymbol, accountUid)

  try {
    const result = await createWallet({
      tokenSymbol,
      accountUid,
    })
    const data = result.data
    const updateResult = await getUpdateUserBalances({
      force: true,
      accountUid: data.accountUid,
    })
    console.log(updateResult)
    console.log(data)
  } catch (error) {
    console.log(error)
  }
}

export const getAccountsByUser = async () => {
  const functions = getFunctions()
  const getAccounts = httpsCallable(functions, "getAccountsByUser")

  let accounts = []

  try {
    const result = await getAccounts()
    const data = result.data

    accounts = data
  } catch (error) {
    console.log(error)
  }
  return accounts
}

export const getAccountByAccountUID = async (accountUid) => {
  const functions = getFunctions()
  const getAccount = httpsCallable(functions, "getAccountByAccountUID")

  let account = {}

  try {
    const result = await getAccount({ accountUid: accountUid })
    const data = result.data

    account = data
  } catch (error) {
    console.log(error)
  }
  return account
}

export const sendTransaction = async (
  serializedTransaction,
  userId,
  address,
  data = null
) => {
  let url = "https://backend-ytpq6wu7ia-ey.a.run.app"
  // if (
  //   process.env.REACT_APP_ENVIRONMENT === "development" ||
  //   process.env.REACT_APP_ENVIRONMENT === "staging"
  // ) {
  //   url = "http://localhost:8081"
  // }

  try {
    const response = await axios.post(`${url}/sendTransaction`, {
      serializedTransaction: serializedTransaction,
      // userId: userId,
      // address: address,
      // data: data,
    })
    return response.data.txid
  } catch (error) {
    console.error("Error sending transaction:", error)
    return null
  }
}
/** This function gets prices from the backend, not the firebase function
 * This is better because the backend maintains a persistent connection to the blockchain
 *
 */
export const getBalanceDirect = async (address, mint, type) => {
  // let url = "https://backend-ytpq6wu7ia-ey.a.run.app"
  // if (process.env.REACT_APP_ENVIRONMENT === "development") {
  //   url = "http://localhost:8081"
  // }

  // if (type === "sol") {
  //   url = `${url}/balance/${address}`
  // } else if (type === "spl") {
  //   url = `${url}/splBalance/${address}/${mint}`
  // }

  // if (mint === "" || mint === null) {
  //   return null
  // }
  let url = "https://us-central1-achi-inu.cloudfunctions.net"

  if (type === "sol") {
    url = `${url}/getSOLBalance?address=${address}`
  } else {
    url = `${url}/getSPLBalance?address=${address}&contract=${mint}`
  }

  if (type !== "sol" && !mint) {
    return null
  }

  try {
    if (address) {
      const response = await axios.get(`${url}`)
      return response.data
    }
  } catch (error) {
    // console.error("Error sending transaction:", error);
    return null
  }
}

export const getTopTokens = async () => {
  const functions = getFunctions()
  const getTopTokens = httpsCallable(functions, "getTopTokens")

  try {
    const result = await getTopTokens()
    const data = result.data
    return data
  } catch (error) {
    console.log(error)
  }
}

export const updateBotBalances = async () => {
  const functions = getFunctions()
  const updateBotBalances = httpsCallable(functions, "updateBotBalances")

  try {
    const result = await updateBotBalances()
    const data = result.data
    return data
  } catch (error) {
    console.log(error)
  }
}

//getPrivateKey
export const getPrivateKey = async (accountUid, accountIndex) => {
  const functions = getFunctions()
  const getPrivateKey = httpsCallable(functions, "getPrivateKey")

  try {
    const result = await getPrivateKey({ accountUid, accountIndex })
    const data = result.data
    return data
  } catch (error) {
    console.log(error)
  }
}

//getPrivateKey
export const fetchWallets = async (uid, accountUid) => {
  const functions = getFunctions()
  const fetchWallets = httpsCallable(functions, "fetchWallets")

  try {
    const result = await fetchWallets({ uid, accountUid })
    return result.data
  } catch (error) {
    console.log(error)
  }
}

// fetch metrics
export const fetchMetrics = async () => {
  const functions = getFunctions()
  const fetchMetrics = httpsCallable(functions, "fetchMetrics")

  try {
    const result = await fetchMetrics()
    return result.data
  } catch (error) {
    console.log(error)
  }
}

export const getWhitelist = async () => {
  try {
    //const result = await getWhitelist()
    const result = await axios.get(
      "https://us-central1-achi-inu.cloudfunctions.net/getWhitelist"
    )
    const data = result["data"]
    return data
  } catch (error) {
    console.log("why  ", error)
  }
}

export const fetchHistoricalData = async (mint) => {
  try {
    const response = await fetch(
      `https://us-central1-achi-inu.cloudfunctions.net/fetchHistoricalData?mint=${mint}`
    )
    const chartData = await response.json()
    return chartData
  } catch (error) {
    console.error("Error fetching historical data: ", error)
  }
}

export const addTransactionToPending = async (uid, accountId, txId) => {
  const functions = getFunctions()
  const addTransactionToPending = httpsCallable(
    functions,
    "addTransactionToPending"
  )

  try {
    const result = await addTransactionToPending({ uid, accountId, txId })
    const data = result.data
    return data
  } catch (error) {
    console.log(error)
  }
}

export const generateReferralCode = async () => {
  const functions = getFunctions()
  const generateReferralCode = httpsCallable(functions, "generateReferralCode")

  try {
    const result = await generateReferralCode()
    const data = result.data
    return data
  } catch (error) {
    console.log(error)
  }
}
