import { useState } from "react";
import { SettingModal } from "./SettingModal";
import { Button } from "./Button";

export const Setting = ({wallets}) => {
  const [openSettingModal, setOpenSettingModal] = useState(false);

  const handleSettingButton = async () => {
    setOpenSettingModal(true);
  };
  
  return (
    <>
      <Button
        handleClick={handleSettingButton}
        isActive={false}
        buttonText="Settings"
      />
      <SettingModal
        wallets={wallets}
        openModal={openSettingModal}
        setOpenModal={setOpenSettingModal}
      />
    </>
  );
};
