import Logo from "./logo.svg"
import LogoWhite from "./logo_white.svg"
import Logo192 from "./logo192.png"

const logos = {
  Logo,
  LogoWhite,
  Logo192
}

export default logos
