import { useState, useEffect } from "react";
import { Button } from "./Button";
import { updateBotInfo } from "../../hooks/functions";
import { auth } from "../../utils/firebase";

export const StartSwap = ({
  direction,
  baseToken,
  targetToken,
  walletAddress,
  status,
  handleError,
  mode,
  amount,
  error,
  tokenList,
  duration
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [status]);

  const handleStatus = async () => {

    handleError("");
    setLoading(true);

    if (status && (mode === "" || mode == null)) {
      handleError("Select Mode")
      setLoading(false)
      return
    }

    if (status && (mode === "AMM" || mode === "Trade") && (amount === "" || amount === 0)) {
      handleError("Balance Error")
      setLoading(false)
      return
    }

    const user = auth.currentUser
    const idToken = await user.getIdToken()
    const refreshToken = user.refreshToken

    const data = {
      wallet_address: walletAddress,
      id_token: idToken,
      refresh_token: refreshToken,
      device_token: localStorage.getItem("deviceToken")
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const apiEndpoint = status
      ? "https://bot.gomemex.com/start"
      : "https://bot.gomemex.com/stop";

    // const apiEndpoint = status
    //   ? "http://localhost:8000/start"
    //   : "http://localhost:8000/stop";

    try {
      if (status) {
        const value = Math.pow(10, tokenList[baseToken]["decimals"]) * amount
        // console.log(value)
        const res = await updateBotInfo(
          direction,
          baseToken,
          targetToken,
          walletAddress,
          mode,
          value,
          duration
        );

        if (res["data"]["status"] !== "success") {
          return;
        }
      }
    } catch (error) {
      setLoading(false);
    }

    fetch(apiEndpoint, options)
      .then((response) => response.json()) // Parse the JSON from the response
      .then((data) => {
        if (!data["status"]) {
          setLoading(false);
          handleError(data["payload"]["error"])
        }
      }) // Handle the response data
      .catch((error) => {
        setLoading(false);
        handleError("Network Error");
      });
  };

  return (
    <>
      {status ? (
        <Button
          handleClick={handleStatus}
          isActive={loading}
          buttonText="Start"
        />
      ) : (
        <Button
          handleClick={handleStatus}
          isActive={loading}
          buttonText="Stop"
          classNames="bg-red-500 hover:bg-red-500/70"
        />
      )}
    </>
  );
};
