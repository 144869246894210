import { Fragment, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Dialog, Transition } from "@headlessui/react"
import { db, auth } from "../../utils/firebase"
import { collection, onSnapshot } from "firebase/firestore"
import { useAuth } from "../../context/useAuth"
import { useTheme } from "../../context/useTheme"
import { Swiper, SwiperSlide } from "swiper/react"
import { useSwiper } from "swiper/react"
import SwapInterface from "./SwapInterface"
import "swiper/css"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const Onboarding = () => {
  const [account, setAccount] = useState({})
  const navigate = useNavigate()

  const authentication = useAuth()
  const [OnboardingFlag, setOnboardingFlag] = useState(1)

  useEffect(() => {
    if (!authentication.isOnboarding) {
      navigate("/dashboard")
    }
  }, [authentication.isOnboarding, navigate])

  useEffect(() => {
    const currentUser = auth.currentUser
    const pathToDocuments = `users/${currentUser?.uid}/accounts`
    const unsubscribe = onSnapshot(
      collection(db, pathToDocuments),
      (querySnapshot) => {
        const accounts = []
        querySnapshot.forEach((doc) => {
          accounts.push({ ...doc.data(), uid: doc.id })
        })
        //sort accounts by creation date
        accounts.sort((a, b) => a.accountIndex - b.accountIndex)
        setAccount(accounts[0])
      }
    )

    return unsubscribe
  }, [account])

  const theme = useTheme()

  const SwiperButtonNext = ({ children }) => {
    const swiper = useSwiper()
    return (
      <button
        className="rounded-md w-[120px] md:w-[140px] h-full py-1 px-4 md:px-6 md:py-2 mx-1 font-bold flex flex-row md:flex-col gap-[2px] items-center justify-center md:mb-3 xl:mb-0 bg-themeColor-light text-btnTextColor-light"
        onClick={() => {
          swiper.slideNext()
        }}
      >
        <div
          className={classNames(
            theme.currentTheme === "dogTheme"
              ? "text-dogTheme-textStrongColor"
              : theme.currentTheme === "lightTheme"
              ? "text-lightTheme-textStrongColor"
              : "text-darkTheme-textStrongColor",
            "text-[15px] md:text-[20px]"
          )}
        >
          Next
        </div>
      </button>
    )
  }

  const SwiperButtonBack = ({ children }) => {
    const swiper = useSwiper()
    return (
      <button
        className="bg-[#aaaaaa] rounded-md w-[120px] md:w-[140px] h-full py-1 px-4 md:px-6 md:py-2 mx-1 font-bold flex flex-row md:flex-col gap-[2px] items-center justify-center md:mb-3 xl:mb-0"
        onClick={() => {
          swiper.slidePrev()
        }}
      >
        <div
          className="text-[15px] md:text-[20px] text-black"
        >
          Back
        </div>
      </button>
    )
  }

  return (
    <div className="p-0 m-0 w-screen h-screen">
      {/* --------------------------------------------------Onboarding part--------------------------------------------------------- */}
      {/* STEP 1 : Education Guide */}
      {OnboardingFlag === 1 && (
        <Transition.Root show={true} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 flex items-center justify-center"
            onClose={() => setOnboardingFlag(1)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-opacity-75 transition-opacity bg-bgColor-light"
              />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-hidden">
              <div className="flex min-h-full items-center justify-center p-4 text-center md:items-center md:p-0">
                <Transition.Child
                  as={Fragment}
                  unmount={false}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                  enterTo="opacity-100 translate-y-0 md:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 md:scale-100"
                  leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                >
                  <Dialog.Panel
                    className="fixed transform overflow-hidden rounded-lg pb-4 pt-2 text-left shadow-xl transition-all md:my-8 w-[80vw] h-[70vh] max-w-xl md:pb-6 flex flex-col bg-bgColor-light text-textColor-light"
                  >
                    <div className="w-full text-center pt-10 text-2xl md:text-3xl">
                      MEMEX Wallet - Your Key To Web3
                    </div>
                    <div className="w-full flex flex-grow">
                      <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        allowTouchMove={false}
                      >
                        {/* 1: Welcome Slide */}
                        <SwiperSlide>
                          <div className="w-full h-full flex flex-col justify-center items-center text-center gap-4 px-8 py-4">
                            <div className="flex-grow flex flex-col gap-4 items-center justify-center">
                              <p className="text-xl sm:text-2xl md:text-4xl">
                                Welcome to Solana!
                              </p>
                              <p className="text-md sm:text-xl md:text-2xl">
                                Dive into the fast, secure world of Solana and
                                discover the future of digital currency!
                              </p>
                            </div>
                            <div className="flex h-[40px]">
                              <SwiperButtonNext />
                            </div>
                          </div>
                        </SwiperSlide>

                        {/* 2: Introduction to Solana as Currency */}
                        <SwiperSlide>
                          <div className="w-full h-full flex flex-col justify-center items-center text-center gap-4 px-8 py-4">
                            <div className="flex-grow flex flex-col gap-4 items-center justify-center">
                              <p className="text-xl sm:text-2xl md:text-4xl">
                                Solana: The Currency of Choice
                              </p>
                              <p className="text-md sm:text-xl md:text-2xl">
                                On the Solana network, SOL is as fundamental as
                                USD is in the U.S. For simplicity, we denominate
                                all values in your preferred currency, making it
                                easy to manage and understand your transactions.
                              </p>
                            </div>
                            <div className="flex h-[40px] flex-row gap-2">
                              <SwiperButtonBack />
                              <SwiperButtonNext />
                            </div>
                          </div>
                        </SwiperSlide>

                        {/* 3: Funding Your Wallet */}
                        <SwiperSlide>
                          <div className="w-full h-full flex flex-col justify-center items-center text-center gap-4 px-8 py-4">
                            <div className="flex-grow flex flex-col gap-4 items-center justify-center">
                              <p className="text-xl sm:text-2xl md:text-4xl">
                                Power Up Your Wallet With SOL
                              </p>
                              <p className="text-md sm:text-xl md:text-2xl">
                                To activate and use your wallet on the Solana
                                network, think of SOL like fuel for your
                                car—it's essential! You can easily fill up with
                                SOL using a Card, ACH, Apple Pay, Google Pay, or
                                one of over 140 other currencies.
                              </p>
                            </div>
                            <div className="flex flex-row gap-1 h-[40px]">
                              <SwiperButtonBack />
                              <SwiperButtonNext />
                            </div>
                          </div>
                        </SwiperSlide>

                        {/* 4: Understanding Swaps */}
                        <SwiperSlide>
                          <div className="w-full h-full flex flex-col justify-center items-center text-center gap-4 px-8 py-4">
                            <div className="flex-grow flex flex-col gap-4 items-center justify-center">
                              <p className="text-xl sm:text-2xl md:text-4xl">
                                Trading Made Simple
                              </p>
                              <p className="text-md sm:text-xl md:text-2xl">
                                Swap SOL for other cryptocurrencies directly
                                from your wallet in just a few clicks—trading
                                made easy!
                              </p>
                            </div>
                            <div className="flex flex-row gap-1 h-[40px]">
                              <SwiperButtonBack />
                              <button
                                className="rounded-md w-[120px] md:w-[140px] h-full py-1 px-4 md:px-2 md:py-2 mx-1 font-bold flex flex-row md:flex-col gap-[2px] items-center justify-center md:mb-3 xl:mb-0 bg-themeColor-light text-btnTextColor-light"
                                onClick={() => {
                                  setOnboardingFlag(2)
                                }}
                              >
                                <div
                                  className="text-[15px] md:text-[20px]"
                                >
                                  Get Started
                                </div>
                              </button>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}

      {/* STEP 2 : Onramper modal */}
      {OnboardingFlag === 2 && (
        <Transition.Root show={true} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 flex items-center justify-center"
            onClose={() => setOnboardingFlag(2)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-opacity-75 transition-opacity bg-bgColor-light"
              />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-hidden">
              <div className="flex min-h-full items-center justify-center p-4 text-center md:items-center md:p-0">
                <Transition.Child
                  as={Fragment}
                  unmount={false}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                  enterTo="opacity-100 translate-y-0 md:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 md:scale-100"
                  leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                >
                  <Dialog.Panel
                    className="fixed transform overflow-hidden rounded-lg pb-4 pt-2 text-left shadow-xl transition-all md:my-8 w-[90%] max-w-md md:pb-6 bg-bgColor-light"
                  >
                    <div className="flex flex-col gap-3 justify-center items-center text-center">
                      <div className="w-[80%] flex flex-row justify-between pt-5 relative">
                        <div className="w-3 h-3 rounded-full bg-dogTheme-primary z-10"></div>
                        <div className="w-3 h-3 rounded-full bg-[#888888] z-10"></div>
                        <div className="w-3 h-3 rounded-full bg-[#888888] z-10"></div>
                        <div className="w-full h-[2px] absolute bg-[#888888] top-[78%] z-0"></div>
                      </div>
                      <div className="md:text-2xl text-xl py-3 font-semibold flex flex-col gap-2">
                        <span>STEP 1 - BUY SOL</span>
                        <span className="text-[16px]">
                          Top off your wallet with SOL
                        </span>
                      </div>
                    </div>
                    <iframe
                      title="onRamper WIdget"
                      src={`https://buy.onramper.com?wallets=SOL:${account.address}&onlyCryptoNetworks=solana&apiKey=pk_prod_01HSXVQTS1574EJYWDXSC51EK2&mode=buy`}
                      style={{
                        width: "100%",
                        height: "70vh",
                      }}
                    />
                    <div className="flex flex-row gap-2 w-full justify-center items-center mt-3">
                      <button
                        className="rounded-md w-[120px] h-full py-1 px-4 md:px-6 md:py-2 mx-1 font-bold flex flex-row md:flex-col gap-[2px] items-center justify-center md:mb-3 xl:mb-0 bg-[#888888]"
                        onClick={() => {
                          setOnboardingFlag(1)
                        }}
                      >
                        <div
                          className={classNames(
                            theme.currentTheme === "dogTheme"
                              ? "text-dogTheme-textStrongColor"
                              : theme.currentTheme === "lightTheme"
                              ? "text-lightTheme-textStrongColor"
                              : "text-darkTheme-textStrongColor",
                            "text-[15px] md:text-[20px]"
                          )}
                        >
                          Back
                        </div>
                      </button>
                      <button
                        className={classNames(
                          theme.currentTheme === "dogTheme"
                            ? "bg-dogTheme-primary"
                            : theme.currentTheme === "lightTheme"
                            ? "bg-lightTheme-gradient"
                            : "bg-darkTheme-gradient",
                          "rounded-md w-[120px] h-full py-1 px-4 md:px-6 md:py-2 mx-1 font-bold flex flex-row md:flex-col gap-[2px] items-center justify-center md:mb-3 xl:mb-0"
                        )}
                        onClick={() => {
                          setOnboardingFlag(3)
                        }}
                      >
                        <div
                          className={classNames(
                            theme.currentTheme === "dogTheme"
                              ? "text-dogTheme-textStrongColor"
                              : theme.currentTheme === "lightTheme"
                              ? "text-lightTheme-textStrongColor"
                              : "text-darkTheme-textStrongColor",
                            "text-[15px] md:text-[20px]"
                          )}
                        >
                          Next
                        </div>
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}

      {/* STEP 3 : Swap modal */}
      {OnboardingFlag === 3 && (
        <Transition.Root show={true} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 flex items-center justify-center"
            onClose={() => setOnboardingFlag(2)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className={classNames(
                  theme.currentTheme === "dogTheme"
                    ? "bg-dogTheme-bgColor"
                    : theme.currentTheme === "lightTheme"
                    ? "bg-lightTheme-bgColor"
                    : "bg-darkTheme-bgColor",
                  "fixed inset-0 bg-opacity-75 transition-opacity"
                )}
              />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-hidden">
              <div className="flex min-h-full items-center justify-center p-4 text-center md:items-center md:p-0">
                <Transition.Child
                  as={Fragment}
                  unmount={false}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                  enterTo="opacity-100 translate-y-0 md:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 md:scale-100"
                  leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                >
                  <Dialog.Panel
                    className={classNames(
                      theme.currentTheme === "dogTheme"
                        ? "bg-dogTheme-bgColor"
                        : theme.currentTheme === "lightTheme"
                        ? "bg-lightTheme-bgColor"
                        : "bg-darkTheme-bgColor",
                      "fixed transform overflow-hidden rounded-lg pb-4 pt-2 text-left shadow-xl transition-all md:my-8 w-[90%] max-w-md md:pb-6"
                    )}
                  >
                    <SwapInterface
                      account={account}
                      handleOnboarding={setOnboardingFlag}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
      {/* ------------------------------------------------End Onboarding part------------------------------------------------------- */}
    </div>
  )
}
