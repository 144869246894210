import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db, auth } from "../../utils/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { SelectToken } from "./SelectToken";
import { TokensListModal } from "./TokensListModal";
import { ResultToken } from "./ResultToken";
import { StartSwap } from "./StartSwap";
import { TokenBalance } from "./TokenBalance";
import { SelectMode } from "./SelectMode";
import {
  ArrowsRightLeftIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { images } from "../../assets";
import { IndividualBotLoader } from "../../components/ContentLoader";
import { getApprovedTokens } from "../../hooks/handleApprovedTokens";
import { AMMBalance } from "./AMMBalance";
import { InputAmount } from "./InputAmount";
import { PercentageTabs } from "./PercentageTabs";
import { getBalanceDirect } from "../../hooks/functions";
import { SelectDuration } from "./SelectDuration";
import { useTheme } from "../../context/useTheme";

export const IndividualBot = () => {
  const { walletAddress } = useParams();
  const [status, setStatus] = useState(true); // True - not running, False - running
  const [showBaseTokensModal, setShowBaseTokensModal] = useState(false);
  const [showTargetTokensModal, setShowTargetTokensModal] = useState(false);
  const [data, setData] = useState({
    baseToken: "",
    baseTokenBalance: 0,
    direction: true,
    status: true,
    targetToken: "",
    targetTokenBalance: 0,
    walletAddress: "",
    mode: "",
    inputAmount: 0,
    outputAmount: 0,
  });
  const [baseToken, setBaseToken] = useState();
  const [baseTokenBalance, setBaseTokenBalance] = useState({
    amount: 0,
    price: 0,
  });
  const [targetToken, setTargetToken] = useState();
  const [targetTokenBalance, setTargetTokenBalance] = useState({
    amount: 0,
    price: 0,
  });
  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState(true); // True - Result is base token, False - Result is target token
  const [messageList, setMessageList] = useState([]);
  const [error, setError] = useState("");
  const [mode, setMode] = useState("");
  const [inputAmount, setInputAmount] = useState(0);
  const [approvedTokensList, setApprovedTokensList] = useState({});
  const [solBalance, setSolBalance] = useState(0)
  const [duration, setDuration] = useState(0);

  const theme = useTheme()

  useEffect(() => {
    let isMounted = true;
    if (isMounted) setLoading(true);
    // Load Bot Info
    const currentUser = auth.currentUser;
    const pathToBots = `bots/${currentUser?.uid}/botDetails/${walletAddress}`;
    const unsubscribeBots = onSnapshot(doc(db, pathToBots), (docSnapshot) => {
      const botInfo = docSnapshot.data();

      if (!botInfo || Object.keys(botInfo).length === 0) {
        setData({});
      } else {
        setData(botInfo);
      }
      if (isMounted) setLoading(false);
    });

    // Load Bot Messages
    const pathToBotMessages = `botMessages/${walletAddress}`;
    const unsubscribeBotMessages = onSnapshot(
      doc(db, pathToBotMessages),
      (docSnapshot) => {
        const messages = docSnapshot.data();

        if (!messages || Object.keys(messages).length === 0) {
          setMessageList({});
        } else {
          setMessageList(messages["messages"]);
        }
      }
    );

    const fetchTokens = async () => {
      const res = await getApprovedTokens();
      if (isMounted) setApprovedTokensList(res);
    };

    fetchTokens();

    return () => {
      isMounted = false;
      unsubscribeBots();
      unsubscribeBotMessages();
    };
  }, []);

  useEffect(() => {
    setBaseToken(data.baseToken);
  }, [data.baseToken]);

  useEffect(() => {
    setTargetToken(data.targetToken);
  }, [data.targetToken]);

  // useEffect(() => {
  //   setDirection(data.direction);
  // }, [data.direction]);

  useEffect(() => {
    setStatus(data.status);
    setError("")
  }, [data.status]);

  useEffect(() => {
    if (data.mode) {
      setMode(data.mode);
    } else {
      setMode("Swap")
    }
  }, [data.mode]);

  // useEffect(() => {
  //   if (data.duration) {
  //     setDuration(data.duration);
  //   } else {
  //     setDuration(1)
  //   }
  // }, [data.duration]);

  useEffect(() => {
    if (mode) {
      setError("");
    }
  }, [mode]);

  useEffect(() => {
    const fetchSOL = async () => {
      const res = await getBalanceDirect(walletAddress, "So11111111111111111111111111111111111111112", "sol")
      if (res && res.decimals !== undefined) {
        setSolBalance(
          Number(res["amount"] / Math.pow(10, res["decimals"])).toFixed(4)
        );
      } else {
        setSolBalance(0);
      }
    }

    fetchSOL()
  }, [data.baseTokenBalance, data.targetTokenBalance])

  // useEffect(() => {
  //   if (mode === "AMM" && status) {
  //     if (inputAmount > baseTokenBalance["amount"]) {
  //       setError("Balance Error");
  //     } else {
  //       setError("");
  //     }
  //   }
  // }, [inputAmount, mode, baseTokenBalance, status]);

  // Swap base token and target token
  const revertTokens = () => {
    const tempToken = baseToken;
    setBaseToken(targetToken);
    setTargetToken(tempToken);
  };

  return (
    <div className="flex flex-col w-full overflow-x-hidden text-textColor-light dark:text-textColor-dark">
      {loading ? (
        <IndividualBotLoader />
      ) : (
        <>
          {/* header */}
          <div className="flex flex-col tb:flex-row gap-1 tb:gap-4 items-start tb:items-end ml-4 mb-5">
          <img src={theme.currentTheme === "light" ? images.MemexLogo : images.MemexWhiteLogo} className="w-40" alt="MemexLogo" />
            <span className="text-[10px] lp:text-[14px] mb-[6px]">
              {walletAddress}
            </span>
            <span
              className={`${
                !status ? "bg-green-500" : "bg-red-500"
              } rounded-xl px-[8px] py-[1px] text-white text-[12px] lp:text-[14px] mb-[6px]`}
            >
              {!status ? "Running" : "Stopped"}
            </span>
          </div>
          {/* Actions */}
          <div className="w-full tb:w-4/5 flex flex-col tb:flex-row gap-4">
            {/* Input Amount */}
            <div className="flex flex-col w-full tb:w-[30%] justify-center tb:justify-start">
              {/* Total Balance in USD */}
              <div className="w-full flex justify-center mt-4 mb-1 h-[50px]">
                <InputAmount
                  mode={mode}
                  amount={inputAmount}
                  baseTokenBalance={baseTokenBalance}
                  handleAmount={setInputAmount}
                  status={status}
                />
              </div>

              {/* Available SOL */}
              <p className="text-center mb-1 text-sm">
                <span className="mr-2">Available SOL:</span> {solBalance}
              </p>

              {/* Percentage Tabs */}
              <div className="w-full justify-center text-center mb-3">
                <PercentageTabs
                  status={status}
                  mode={mode}
                  handleAmount={setInputAmount}
                  baseToken={baseToken}
                  tokenList={approvedTokensList}
                  walletAddress={walletAddress}
                />
              </div>
            </div>
            {/* Tokens */}
            <div className="w-full tb:w-[40%] flex flex-row gap-2 justify-center tb:justify-start items-center">
              {/* Base Token */}
              <div className="w-[40%]">
                <SelectToken
                  tokenSymbol={baseToken}
                  status={status}
                  handleModal={setShowBaseTokensModal}
                  direction="row"
                />
              </div>

              <div className="w-[20%] flex justify-center items-center">
                {status ? (
                  <ArrowsRightLeftIcon
                    className="w-12 h-12 hover:cursor-pointer hover:bg-panelColor-light dark:hover:bg-panelColor-dark p-2 rounded-md"
                    onClick={revertTokens}
                  />
                ) : (
                  <ArrowPathIcon className="w-12 h-12 p-2 animate-spin" />
                )}
              </div>

              {/* Target Token */}
              <div className="w-[40%]">
                <SelectToken
                  tokenSymbol={targetToken}
                  status={status}
                  handleModal={setShowTargetTokensModal}
                  direction="row"
                />
              </div>
            </div>
            {/* Actions */}
            <div className="w-full tb:w-[30%] flex justify-center items-center">
              <StartSwap
                direction={direction}
                baseToken={baseToken}
                targetToken={targetToken}
                status={status}
                handleError={setError}
                walletAddress={walletAddress}
                error={error}
                mode={mode}
                amount={inputAmount}
                tokenList={approvedTokensList}
                duration={duration}
              />
            </div>
          </div>
          {/* Details */}
          <div className="w-full flex flex-col lp:flex-row gap-3 lp:gap-0 mt-3 justify-center tb:justify-start">
            {/* Result Token */}
            {/* <div className="w-full lp:w-1/3 tb:w-1/4 mx-5 flex justify-center lp:justify-start items-center">
              <span className="mr-3 text-[14px]">Result :</span>
              <ResultToken
                baseTokenSymbol={baseToken}
                targetTokenSymbol={targetToken}
                direction={direction}
                handleDirection={setDirection}
                status={status}
              />
            </div> */}
            {/* Select Mode */}
            <div className="w-full lp:w-1/2 tb:w-1/3 mx-5 flex justify-center tb:justify-start items-center">
              <span className="text-[14px] mr-4">Mode :</span>
              <SelectMode
                selectedMode={mode}
                handleMode={setMode}
                status={status}
              />
            </div>
            {/* Select Duration */}
            {/* <div className="w-full lp:w-1/3 tb:w-1/4 mx-5 flex justify-center lp:justify-start items-center">
              <span className="text-[14px] mr-5">Time :</span>
              <SelectDuration
                selectedDuration={duration}
                handleDuration={setDuration}
                status={status}
              />
            </div> */}
          </div>
          {/* Balance */}
          <div className="w-full flex flex-col tb:flex-row justify-center tb:justify-start gap-3 mt-3 px-5">
            {/* Wallet Balance */}
            <div className="w-full tb:w-1/2 flex justify-center tb:justify-start gap-5">
              <div className="w-1/2 lp:w-1/3">
                {/* Base Token */}
                <TokenBalance
                  tokenSymbol={baseToken}
                  balance={data.baseTokenBalance}
                  handleBalance={setBaseTokenBalance}
                  status={status}
                  walletAddress={walletAddress}
                  tokenList={approvedTokensList}
                />
              </div>
              <div className="w-1/2 lp:w-1/3">
                {/* Target Token */}
                <TokenBalance
                  tokenSymbol={targetToken}
                  balance={data.targetTokenBalance}
                  handleBalance={setTargetTokenBalance}
                  status={status}
                  walletAddress={walletAddress}
                  tokenList={approvedTokensList}
                />
              </div>
            </div>
            {/* AMM Balance */}
            <div className="w-full tb:w-1/2 flex justify-center tb:justify-start gap-5">
              {/* <p className="w-full text-center">AMM Balances</p> */}
              <div className="w-1/2 lp:w-1/3">
                <AMMBalance
                  tokenSymbol={baseToken}
                  balance={data.inputAmount}
                  tokenList={approvedTokensList}
                />
              </div>
              <div className="w-1/2 lp:w-1/3">
                <AMMBalance
                  tokenSymbol={targetToken}
                  balance={data.outputAmount}
                  tokenList={approvedTokensList}
                />
              </div>
            </div>
          </div>
          {/* Error */}
          <p className="text-red-500 text-sm px-5 py-2 text-center lp:text-left">
            {error}
          </p>
          {/* Progress Console */}
          <div className="w-full bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark rounded-xl min-h-[200px] mt-2 p-4">
            <div className="text-[12px] flex flex-col break-words">
              {messageList.length > 0 &&
                messageList.map((item, index) => (
                  <code key={index} className="flex flex-col lp:flex-row">
                    <span>{item.time}</span>
                    <span className="mx-2 hidden lp:inline">---</span>
                    <span>{item.content}</span>
                    <span className="inline lp:hidden">
                      -----------------------------------
                    </span>
                  </code>
                ))}
            </div>
          </div>
          {/* Tokens List Modal for Target token */}
          <TokensListModal
            setSelectedToken={setTargetToken}
            showStatus={showTargetTokensModal}
            setShowStatus={setShowTargetTokensModal}
            tokenList={approvedTokensList}
          />

          {/* Tokens List Modal for Base token */}
          <TokensListModal
            setSelectedToken={setBaseToken}
            showStatus={showBaseTokensModal}
            setShowStatus={setShowBaseTokensModal}
            tokenList={approvedTokensList}
          />
        </>
      )}
    </div>
  );
};
