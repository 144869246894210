import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { IndividualBotSetting } from "./IndividualBotSetting";

export const SettingModal = ({ wallets, openModal, setOpenModal }) => {
  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setOpenModal(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-panelColor-light fixed inset-0 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-0 text-center md:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="bg-bgColor-light dark:bg-bgColor-dark text-textColor-light dark:text-textColor-dark relative transform rounded-xl text-left shadow-xl transition-all mx-3 px-3 pb-8 w-full lp:w-3/4 pc:w-2/3">
                <div className="flex mr-2 h-12 items-center justify-end">
                  <XMarkIcon
                    className="h-6 w-6 cursor-pointer mt-2"
                    aria-hidden="true"
                    onClick={() => setOpenModal(false)}
                  />
                </div>
                <div className="flex flex-col gap-3 items-center">
                  <p className="text-xl">Setting</p>
                  <div className="w-full">
                    {/* header */}
                    <div className="w-full flex gap-2 text-center mt-2">
                      <div className="w-[20%] pb-2 border-b-2">Wallet</div>
                      <div className="w-[25%] pb-2 border-b-2">Slippage</div>
                      <div className="w-[25%] pb-2 border-b-2">Speed</div>
                      <div className="w-[20%] pb-2 border-b-2">Frequency</div>
                      <div className="w-[10%] pb-2 border-b-2"></div>
                    </div>
                    {/* Content */}
                    {wallets.length > 0 &&
                      wallets.map((item, index) => (
                        <IndividualBotSetting key={index} walletAddress={item} />
                      ))}
                    
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
